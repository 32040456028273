import Input from '@mui/material/Input';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

interface Props {
  value: string;
  onValueChange: (value: boolean) => void;
}

const BooleanEditor = ({ value, onValueChange }: Props): JSX.Element => (
  <Select
    input={<Input />}
    value={value ? 'Yes' : 'No'}
    onChange={(event) => onValueChange(event.target.value === 'Yes')}
    style={{ width: '100%' }}
  >
    <MenuItem value="Yes">Yes</MenuItem>
    <MenuItem value="No">No</MenuItem>
  </Select>
);

export default BooleanEditor;
